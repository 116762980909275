import React, {useState, useRef, useEffect, useCallback} from 'react';
import {StyleSheet, Platform, Linking} from 'react-native';
import {
  Box,
  Text,
  HStack,
  IconButton,
  AlertDialog,
  Button,
  Stack,
  VStack,
  Pressable,
  useTheme,
} from 'native-base';
import {createIconSetFromFontello} from 'react-native-vector-icons';
import Trans from '../../Components/Trans';
import GeneralAction from '../../../Actions/GeneralAction';
import APIAction from '../../../Actions/APIAction';
import RequestCacheAction from '../../../Actions/RequestCacheAction';
import TranslationAction from '../../../Actions/TranslationAction';
import ProjectEditPane from './ProjectEditPane';
import ProjectShiftList from './ProjectShiftList';
import ProjectCopyModal from './ProjectCopyModal';

import main from '../../../Assets/Styles/main.json';
import lineAwesomeConfig from '../../../Assets/Fontello/line-awesome-config.json';

const mainStyle = StyleSheet.create(main);
const Icon = createIconSetFromFontello(lineAwesomeConfig);

const Project = props => {
  const {colors} = useTheme(),
    firstLoad = useRef(true),
    [deleteModal, setDeleteModal] = useState(false),
    [copyModal, setCopyModal] = useState(false),
    [editPane, setEditPane] = useState(false),
    editPaneFirst = useRef(false),
    [displayDate, setDisplayDate] = useState(null),
    [language, setLanguage] = useState(null),
    [fullAddress, setFullAddress] = useState(null);
  const load = useCallback(() => {
    const init = async () => {
      //format dates
      let dateFormat = {
        day: 'numeric',
        month: 'long',
        year: 'numeric',
      };

      let startDate = await GeneralAction.formatLocal(
        new Date(props.project.startDate),
        dateFormat,
      );

      setDisplayDate(`${startDate}`);

      //language
      let currLang = await TranslationAction.getSelectedLanguage();
      setLanguage(currLang);

      //full address
      let country = null;
      if (props.project.address)
        country = await GeneralAction.getCountry(props.project.address.country);
      setFullAddress(
        `${props.project.address?.street} ${
          props.project.address?.streetNumber
        }${props.project.address?.suite ?? ''}, ${props.project.address?.zip} ${
          props.project.address?.place
        }, ${country}`,
      );
    };
    init();
  }, [props]);

  useEffect(() => {
    if (firstLoad.current) {
      load();
      firstLoad.current = false;
    }
  }, [load]);

  const remove = async () => {
    let response = await APIAction.request({
      method: 'PATCH',
      url: `/api/projects/${props.project.id}`,
      body: {
        active: false,
      },
    });
    await RequestCacheAction.clear({url: 'projects'});
    setDeleteModal(false);

    if (typeof props.onReload === 'function') {
      props.onReload();
    }
  };

  return (
    <Box width={{base: '100%', xl: '32%'}}>
      {language && (
        <>
          <Box style={props.style}>
            <Box style={[mainStyle.whiteBoxCollapse, {margin: 0}]}>
              <Box style={[mainStyle.collapseButton, {paddingVertical: 10}]}>
                <Box w={'100%'} maxW={'100%'}>
                  <VStack space={1}>
                    <VStack space={1}>
                      <HStack
                        alignItems={'center'}
                        justifyContent={'space-between'}
                        space={2}
                        style={{flexGrow: 1, flexShrink: 1}}>
                        <Box flexGrow={1} flexShrink={1}>
                          <HStack
                            space={2}
                            justifyContent={'center'}
                            alignItems={'center'}>
                            {/* <Box
                                                            bg={props.color}
                                                            w={5}
                                                            h={5}
                                                            borderRadius={'full'}
                                                        /> */}
                            <Box flexGrow={1} flexShrink={1}>
                              <Stack
                                direction={{
                                  base: 'column',
                                  lg: 'column',
                                }}
                                space={{
                                  base: 0,
                                  lg: 0,
                                }}
                                alignItems={{
                                  base: 'flex-start',
                                  lg: 'flex-start',
                                }}>
                                <Text style={[mainStyle.mediumTitle]}>
                                  {props.project.name}
                                </Text>
                                <Text style={mainStyle.smallText}>
                                  {displayDate}
                                </Text>
                              </Stack>
                            </Box>
                          </HStack>
                        </Box>
                        <Box>
                          <HStack space={2}>
                            <IconButton
                              variant={'subtle'}
                              colorScheme={'info'}
                              borderRadius={'full'}
                              icon={
                                <Icon
                                  color={colors['info']['600']}
                                  size={16}
                                  name={'copy'}
                                />
                              }
                              onPress={() => {
                                setCopyModal(true);
                              }}
                            />
                            {new Date(props.project.endDate) > new Date() && (
                              <IconButton
                                variant={'subtle'}
                                colorScheme={'warning'}
                                borderRadius={'full'}
                                icon={
                                  <Icon
                                    color={'#ea580c'}
                                    size={16}
                                    name={'alternate-pencil'}
                                  />
                                }
                                onPress={() => {
                                  editPaneFirst.current = true;
                                  setEditPane(true);
                                }}
                              />
                            )}
                            {new Date(props.project.endDate) > new Date() && (
                              <IconButton
                                variant={'subtle'}
                                colorScheme={'danger'}
                                borderRadius={'full'}
                                icon={
                                  <Icon
                                    color={'#e11d48'}
                                    size={16}
                                    name={'trash'}
                                  />
                                }
                                onPress={() => {
                                  setDeleteModal(true);
                                }}
                              />
                            )}
                          </HStack>
                        </Box>
                      </HStack>
                      {props.project.address && (
                        <Pressable
                          onPress={() => {
                            // Open map with address of user
                            let os = Platform.OS;
                            let url;

                            if (os !== 'web') {
                              url = Platform.select({
                                ios: `maps://0,0?q=${fullAddress}`,
                                android: `geo:0,0?q=${fullAddress}`,
                              });
                            } else {
                              url = 'https://maps.google.com?q=' + fullAddress;
                            }

                            Linking.openURL(url);
                          }}>
                          <Text style={mainStyle.smallText} underline>
                            <Icon name={'map-marker'} />
                            {fullAddress}
                          </Text>
                        </Pressable>
                      )}
                    </VStack>
                    <ProjectShiftList project={props.project} />
                  </VStack>
                </Box>
              </Box>
            </Box>
          </Box>
          <AlertDialog
            isOpen={deleteModal}
            onClose={() => setDeleteModal(false)}>
            <AlertDialog.Content>
              <AlertDialog.CloseButton />
              <AlertDialog.Header>
                <Text>
                  <Trans>Delete</Trans>
                </Text>
              </AlertDialog.Header>
              <AlertDialog.Body>
                <Text>
                  <Trans>Delete project:</Trans>{' '}
                  <Text bold>{props.project.name}</Text>?
                </Text>
              </AlertDialog.Body>
              <AlertDialog.Footer>
                <Button.Group space={2}>
                  <Button
                    variant={'ghost'}
                    onPress={() => setDeleteModal(false)}>
                    <Text>
                      <Trans>Cancel</Trans>
                    </Text>
                  </Button>
                  <Button
                    colorScheme={'danger'}
                    onPress={() => {
                      remove();
                    }}>
                    <Text color={'#fff'}>
                      <Trans>Delete</Trans>
                    </Text>
                  </Button>
                </Button.Group>
              </AlertDialog.Footer>
            </AlertDialog.Content>
          </AlertDialog>
          <ProjectCopyModal
            project={props.project}
            isOpen={copyModal}
            onClose={() => {
              setCopyModal(false);
            }}
            onReload={props.onReload}
          />
          {editPaneFirst.current && (
            <ProjectEditPane
              title={<Trans>Edit project</Trans>}
              project={props.project}
              open={editPane}
              onClose={() => setEditPane(false)}
              onReload={props.onReload}
            />
          )}
        </>
      )}
    </Box>
  );
};

export default Project;
